<template>
  <v-container fluid>
    <v-row>
      <v-col
        class="mx-auto "
        cols="2"
        sm="2"
        >
        <router-link 
            class="white--text"
            style="text-decoration:none" 
            to="/">
            <v-btn
              color="black"
              text
            >
              <v-icon class="ml-2">fas fa-chevron-left</v-icon>
            </v-btn>
        </router-link>
      </v-col>
      <v-col cols="8" sm="8">
        <h1 
        :class="[$vuetify.breakpoint.smAndDown ? 'display-2': 'display-3']"
        class="text-center font-weight-black mb-5">
            Bienvenue à EFAM
        </h1>
      </v-col>
      <v-col cols="2" sm="2"></v-col>
    </v-row>

<h2
        :class="[$vuetify.breakpoint.smAndDown ? 'display-0': 'display-1']"
        class="font-weight-black mt-15 mb-3">
         Entrée Principale
      </h2>
     <v-row>
        <v-col v-for="(item6, index) in items6" :key="index" cols="12" sm="4">
          <v-card flat color="transparent" class="pt-1 pb-1">
            <v-img :src="item6.img"></v-img>
          </v-card>
        </v-col>
      </v-row>
      <h2
        :class="[$vuetify.breakpoint.smAndDown ? 'display-0': 'display-1']"
        class="font-weight-black mt-15 mb-3">
          Notre Accueil
      </h2>
     <v-row>
        <v-col v-for="(item1, index) in items1" :key="index" cols="12" sm="4">
          <v-card flat color="transparent" class="pt-1 pb-1">
            <v-img :src="item1.img"></v-img>
          </v-card>
        </v-col>
      </v-row>
      <h2
        :class="[$vuetify.breakpoint.smAndDown ? 'display-0': 'display-1']"
        class="font-weight-black mt-15 mb-3">
          Nos bureaux
      </h2>
     <v-row>
        <v-col v-for="(item7, index) in items7" :key="index" cols="12" sm="4">
          <v-card flat color="transparent" class="pt-1 pb-1">
            <v-img :src="item7.img"></v-img>
          </v-card>
        </v-col>
      </v-row>

      <h2
        :class="[$vuetify.breakpoint.smAndDown ? 'display-0': 'display-1']"
        class="font-weight-black mt-15 mb-3">
          Salle de conferences
      </h2>
     <v-row>
        <v-col v-for="(item2, index) in items2" :key="index" cols="12" sm="4">
          <v-card flat color="transparent" class="pt-1 pb-1">
            <v-img :src="item2.img"></v-img>
          </v-card>
        </v-col>
      </v-row>

      <h2
        :class="[$vuetify.breakpoint.smAndDown ? 'display-0': 'display-1']"
        class="font-weight-black mt-15 mb-3">
          Salles de cours
      </h2>
     <v-row>
        <v-col v-for="(item3, index) in items3" :key="index" cols="12" sm="4">
          <v-card flat color="transparent" class="pt-1 pb-1">
            <v-img :src="item3.img"></v-img>
          </v-card>
        </v-col>
      </v-row>
      
      <h2
        :class="[$vuetify.breakpoint.smAndDown ? 'display-0': 'display-1']"
        class="font-weight-black mt-15 mb-3">
          Salle de soins
      </h2>
     <v-row>
        <v-col v-for="(item4, index) in items4" :key="index" cols="12" sm="4">
          <v-card flat color="transparent" class="pt-1 pb-1">
            <v-img :src="item4.img"></v-img>
          </v-card>
        </v-col>
      </v-row>

      <h2
        :class="[$vuetify.breakpoint.smAndDown ? 'display-0': 'display-1']"
        class="font-weight-black mt-15 mb-3">
         Kichinette
      </h2>
     <v-row>
        <v-col v-for="(item5, index) in items5" :key="index" cols="12" sm="4">
          <v-card flat color="transparent" class="pt-1 pb-1">
            <v-img :src="item5.img"></v-img>
          </v-card>
        </v-col>
      </v-row>

      <h2
        :class="[$vuetify.breakpoint.smAndDown ? 'display-0': 'display-1']"
        class="font-weight-black mt-15 mb-3">
         Salle de prière
      </h2>
     <v-row>
        <v-col v-for="(item8, index) in items8" :key="index" cols="12" sm="4">
          <v-card flat color="transparent" class="pt-1 pb-1">
            <v-img :src="item8.img"></v-img>
          </v-card>
        </v-col>
      </v-row>

      
  </v-container>
</template>
<script>
  export default {
    name: 'Galerie',
     data: () => ({
    items1: [
        {
          id: 3,
          title: "3",
          img: require('@/assets/galerie/Accueil/3.jpg'),
           },
        {
          id: 2,
          title: "2",
          img: require('@/assets/galerie/Accueil/2.jpg'),
         },
        {
          id: 1,
          title: "1",
          img: require('@/assets/galerie/Accueil/1.jpg'),
          },
          {
          id: 8,
          title: "8",
          img: require('@/assets/galerie/Accueil/8.jpg'),
          },
      ],
    items2: [
        {
          id: 4,
          title: "4",
          img: require('@/assets/galerie/Salle de conference/4.jpg'),
          },
        {
          id: 9,
          title: "9",
          img: require('@/assets/galerie/Salle de conference/9.jpg'),
         },
        {
          id: 8,
          title: "8",
          img: require('@/assets/galerie/Salle de conference/8.jpg'),
         },
         {
          id: 12,
          title: "12",
          img: require('@/assets/galerie/Salle de conference/12.jpg'),
         },
         
        {
          id: 14,
          title: "14",
          img: require('@/assets/galerie/Salle de conference/14.jpg'),
         },
        {
          id: 1,
          title: "1",
          img: require('@/assets/galerie/Salle de conference/1.jpg'),
          },
        {
          id: 2,
          title: "2",
          img: require('@/assets/galerie/Salle de conference/2.jpg'),
         },
        {
          id: 5,
          title: "5",
          img: require('@/assets/galerie/Salle de conference/5.jpg'),
         },
        {
          id: 10,
          title: "10",
          img: require('@/assets/galerie/Salle de conference/10.jpg'),
         },
        {
          id: 11,
          title: "11",
          img: require('@/assets/galerie/Salle de conference/11.jpg'),
         },
          {
          id: 6,
          title: "6",
          img: require('@/assets/galerie/Salle de conference/6.jpg'),
          },
        {
          id: 13,
          title: "13",
          img: require('@/assets/galerie/Salle de conference/13.jpg'),
         },
      ],
    items3: [
        {
          id: 14,
          title: "14",
          img: require('@/assets/galerie/Salle de cours/14.jpg'),
         },
        {
          id: 15,
          title: "15",
          img: require('@/assets/galerie/Salle de cours/15.jpg'),
         },
        {
          id: 16,
          title: "16",
          img: require('@/assets/galerie/Salle de cours/16.jpg'),
         },
        {
          id: 9,
          title: "9",
          img: require('@/assets/galerie/Salle de cours/9.jpg'),
         },
        {
          id: 10,
          title: "10",
          img: require('@/assets/galerie/Salle de cours/10.jpg'),
         },
        {
          id: 11,
          title: "11",
          img: require('@/assets/galerie/Salle de cours/11.jpg'),
         },
        {
          id: 5,
          title: "5",
          img: require('@/assets/galerie/Salle de cours/5.jpg'),
         },
        {
          id: 7,
          title: "7",
          img: require('@/assets/galerie/Salle de cours/7.jpg'),
         },
        {
          id: 8,
          title: "8",
          img: require('@/assets/galerie/Salle de cours/8.jpg'),
         },
        {
          id: 12,
          title: "12",
          img: require('@/assets/galerie/Salle de cours/12.jpg'),
         },
        {
          id: 13,
          title: "13",
          img: require('@/assets/galerie/Salle de cours/13.jpg'),
         },
         {
          id: 2,
          title: "2",
          img: require('@/assets/galerie/Salle de cours/2.jpg'),
         },
        {
          id: 3,
          title: "3",
          img: require('@/assets/galerie/Salle de cours/3.jpg'),
          },
          {
          id: 6,
          title: "6",
          img: require('@/assets/galerie/Salle de cours/6.jpg'),
          },
      ],
    items4: [
        {
          id: 1,
          title: "1",
          img: require('@/assets/galerie/Salle de soins/1.jpg'),
          },
        {
          id: 2,
          title: "2",
          img: require('@/assets/galerie/Salle de soins/2.jpg'),
         },
        {
          id: 3,
          title: "3",
          img: require('@/assets/galerie/Salle de soins/3.jpg'),
           },
      ],
    items5: [
        {
          id: 1,
          title: "1",
          img: require('@/assets/galerie/kichinette/1.jpg'),
          },
        {
          id: 3,
          title: "3",
          img: require('@/assets/galerie/kichinette/3.jpg'),
           },
        {
          id: 6,
          title: "6",
          img: require('@/assets/galerie/kichinette/6.jpg'),
           },
        {
          id: 8,
          title: "8",
          img: require('@/assets/galerie/kichinette/8.jpg'),
           },
        {
          id: 9,
          title: "9",
          img: require('@/assets/galerie/kichinette/9.jpg'),
           },
        {
          id: 10,
          title: "10",
          img: require('@/assets/galerie/kichinette/10.jpg'),
           },
        {
          id: 11,
          title: "11",
          img: require('@/assets/galerie/kichinette/11.jpg'),
           },
        
      ],
    items6: [
      
        {
          id: 2,
          title: "2",
          img: require('@/assets/galerie/Entrée/2.jpg'),
         },
         
        {
          id: 1,
          title: "1",
          img: require('@/assets/galerie/Entrée/1.jpg'),
          },
          
        {
          id: 3,
          title: "3",
          img: require('@/assets/galerie/Entrée/3.jpg'),
           },
        {
          id: 4,
          title: "4",
          img: require('@/assets/galerie/Entrée/4.jpg'),
           },
           
        {
          id: 6,
          title: "6",
          img: require('@/assets/galerie/Entrée/6.jpg'),
        },
        {
          id: 5,
          title: "5",
          img: require('@/assets/galerie/Entrée/5.jpg'),
           },
      ],
    items7: [
        {
          id: 5,
          title: "5",
          img: require('@/assets/galerie/Bureaux/5.jpg'),
        },
        {
          id: 6,
          title: "6",
          img: require('@/assets/galerie/Bureaux/6.jpg'),
        },
        {
          id: 19,
          title: "19",
          img: require('@/assets/galerie/Bureaux/19.jpg'),
        },
        {
          id: 10,
          title: "10",
          img: require('@/assets/galerie/Bureaux/10.jpg'),
        },
        {
          id: 12,
          title: "12",
          img: require('@/assets/galerie/Bureaux/12.jpg'),
         },
        {
          id: 11,
          title: "11",
          img: require('@/assets/galerie/Bureaux/11.jpg'),
        },
        {
          id: 14,
          title: "14",
          img: require('@/assets/galerie/Bureaux/14.jpg'),
        },
        {
          id: 13,
          title: "13",
          img: require('@/assets/galerie/Bureaux/13.jpg'),
        },
        {
          id: 15,
          title: "15",
          img: require('@/assets/galerie/Bureaux/15.jpg'),
        },
        {
          id: 16,
          title: "16",
          img: require('@/assets/galerie/Bureaux/16.jpg'),
        },
        {
          id: 17,
          title: "17",
          img: require('@/assets/galerie/Bureaux/17.jpg'),
        },
        {
          id: 18,
          title: "18",
          img: require('@/assets/galerie/Bureaux/18.jpg'),
        },
      ],
    items8: [
        
        {
          id: 14,
          title: "14",
          img: require('@/assets/galerie/kichinette/14.jpg'),
           },
        {
          id: 15,
          title: "15",
          img: require('@/assets/galerie/kichinette/15.jpg'),
           },
      ]
    //
    })
  }
  </script>