<style>
  /* .blur { -webkit-filter: blur(5px); -moz-filter: blur(5px); -o-filter: blur(5px); -ms-filter: blur(5px); filter: blur(5px); } */
#app {
  background: url('../assets/background8.jpg')
    no-repeat center center fixed !important;
  background-size: cover;
}
</style>
<template id="app">
  <v-container fluid>
    <Navbar/>
    <section 
    id="accueil">
      <Parallax/>
    </section>

    <section id="nos-valeurs">
      <NosValeurs/>
    </section>

    <section  id="qsse">
      <Qsse/>
    </section>

    <!-- <v-divider></v-divider> -->
    <section id="pedago">
      <Pedago/>
    </section>

     <section id="formations">
      <Formations/>
    </section>

     <section id="contact">
      <Contact/>
    </section>
    <Footer/>
    
  </v-container>
</template>

<script>
// import Parallax from './sections/Parallax';
  export default {
    name: 'HelloWorld',
    components: {
      Navbar: () => import('@/components/Navbar'),
      Footer: () => import('@/components/Footer'),
      Parallax: () => import('@/sections/Parallax'),
      NosValeurs: () => import('@/sections/Nos-valeurs'),
      Qsse: () => import('@/sections/qsse'),
      Pedago: () => import('@/sections/pedago'),
      Formations: () => import('@/sections/formations'),
      Contact: () => import('@/sections/contact'),
    },
    data: () => ({
      ecosystem: [
        {
          text: 'vuetify-loader',
          href: 'https://github.com/vuetifyjs/vuetify-loader',
        },
        {
          text: 'github',
          href: 'https://github.com/vuetifyjs/vuetify',
        },
        {
          text: 'awesome-vuetify',
          href: 'https://github.com/vuetifyjs/awesome-vuetify',
        },
      ],
      importantLinks: [
        {
          text: 'Documentation',
          href: 'https://vuetifyjs.com',
        },
        {
          text: 'Chat',
          href: 'https://community.vuetifyjs.com',
        },
        {
          text: 'Made with Vuetify',
          href: 'https://madewithvuejs.com/vuetify',
        },
        {
          text: 'Twitter',
          href: 'https://twitter.com/vuetifyjs',
        },
        {
          text: 'Articles',
          href: 'https://medium.com/vuetify',
        },
      ],
      whatsNext: [
        {
          text: 'Explore components',
          href: 'https://vuetifyjs.com/components/api-explorer',
        },
        {
          text: 'Select a layout',
          href: 'https://vuetifyjs.com/getting-started/pre-made-layouts',
        },
        {
          text: 'Frequently Asked Questions',
          href: 'https://vuetifyjs.com/getting-started/frequently-asked-questions',
        },
      ],
    }),
  }
</script>
