import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import VueRouter from 'vue-router'
import HelloWorld from './views/HelloWorld'
import PageFormations from './views/PageFormations'
import Galerie from './views/galerie'
import './scss/main.scss'

Vue.use(VueRouter);

const routes = 
[
  {
    path :'/',
    component: HelloWorld
  },
  {
    path :'/formation/:id',
    component: PageFormations
  },
  {
    path :'/galerie',
    component: Galerie
  }
]
const router = new VueRouter(
  {
    routes
  }
)
Vue.config.productionTip = false

new Vue({
  vuetify,
  store,
  router : router,
  render: h => h(App)
}).$mount('#app')
