<template>

    <v-row class="text-center my-5" align="center" justify="center" >
        <Navbar/>
       <v-card width="100%">
                
                <img 
                    v-if="img != null"
                    class="mt-10"
                    v-bind:src="require('../assets/images/' + this.$route.query.formation.img)" 
                    
                    :height="$vuetify.breakpoint.smAndDown ? 300 : window.height - 200"
                    width="100%"
                    max-width="100%"
                    alt=""
                    style="transform: none !important">
            
                <v-tabs
                    v-model="currentItem"
                    fixed-tabs
                    color="blue darken-4"
                    slider-color="blue darken-4"
                    mobile-breakpoint
                    icons-and-text
                    centered
                    >
                    <v-tab
                        active-class
                        href="#tab-details"
                    >
                        Détails
                        <v-icon >
                            fas fa-info-circle
                        </v-icon>
                    </v-tab>
                    <v-tab
                        v-if="this.$route.query.formation.formateurs != null"
                        href="#tab-formateurs"
                    >
                        Formateurs
                        <v-icon>
                            fas fa-user-circle
                        </v-icon>
                    </v-tab>
                </v-tabs>

            <v-tabs-items v-model="currentItem">
                <v-tab-item
                   
                    value="tab-details"
                    >
                    <v-card flat>
                        <v-card-text>
                            <v-tabs 
                                color="blue darken-4"
                                slider-color="blue darken-4"
                                show-arrows
                                :vertical="$vuetify.breakpoint.smAndDown ? false : true">
                                <v-tab v-if="this.$route.query.formation.describe != null"
                                >
                                    <v-icon left>
                                        fas fa-eye
                                    </v-icon>
                                    Aperçu
                                </v-tab>
                                <v-tab v-if="this.$route.query.formation.objectifs != null">
                                    <v-icon left>
                                        fas fa-check-square
                                    </v-icon>
                                    Objectifs
                                </v-tab>
                                <v-tab>
                                    <v-icon left>
                                        fas fa-list-ol
                                    </v-icon>
                                    Programme
                                </v-tab>

                                <v-tab-item  v-if="this.$route.query.formation.describe != null">
                                    <v-card flat>
                                        <v-card-text 
                                            :class="[$vuetify.breakpoint.smAndDown ? '': 'headline text-justify']"
                                            class="black--text">
                                            <v-col class="text-center" cols="12">
                                                <v-icon
                                                    class="mt-3 black--text"
                                                    size="65">
                                                        {{this.$route.query.formation.icon}}
                                                </v-icon>
                                            </v-col>
                                            <v-col class="text-center mb-10"  cols="12">
                                                <h1>{{this.$route.query.formation.title}}</h1>
                                            </v-col>
                                            <p class=" mx-5 ">{{this.$route.query.formation.describe}}</p>
                                            <h2 class="text-center mt-4 mb-2 font-weight-bold">Public visé</h2>
                                            <p class=" mx-5 mb-6">{{this.$route.query.formation.publicVise}}</p>
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>
                                <v-tab-item  v-if="this.$route.query.formation.objectifs != null">
                                    <v-card flat>
                                        <v-card-text
                                            :class="[$vuetify.breakpoint.smAndDown ? '': 'headline text-justify']"
                                            class="black--text">
                                            <h2 class="text-center mt-4 mb-2 font-weight-bold">Objectifs de la formation</h2>
                                            <ul class="fa-ul ml-0 mr-5 ">
                                                <li
                                                    class="text-left mb-1"
                                                    v-for="(obj,o) in objectifs"
                                                    :key="o"
                                                    >
                                                    <v-icon class="mr-2">
                                                        fas fa-check
                                                    </v-icon>
                                                    {{obj.objectif}}
                                                </li>
                                            </ul>
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>
                                <v-tab-item>
                                    <v-card flat>
                                        <v-card-text
                                            :class="[$vuetify.breakpoint.smAndDown ? '': 'headline text-justify']"
                                            class="black--text">
                                            <h2 class="text-center mt-4 mb-2  font-weight-bold">Programme de la formation</h2>
                                            <ol class=" ml-5">
                                                <li
                                                    class="text-left mb-1"
                                                    v-for="(obj,o) in this.$route.query.formation.prog"
                                                    :key="o"
                                                    >
                                                    {{obj.p}}
                                                </li>
                                            </ol>
                                        </v-card-text>
                                    </v-card>
                            </v-tab-item>
                        </v-tabs>
                    </v-card-text>
                </v-card>
                </v-tab-item>

                <v-tab-item
                    value="tab-formateurs"
                >
                    <v-card flat>
                        <v-card-text>
                            <v-tabs
                                color="blue darken-4"
                                slider-color="blue darken-4"
                                show-arrows
                                :vertical="$vuetify.breakpoint.smAndDown ? false : true">
                                <v-tab
                                class="justify"
                                    v-for="(obj,o) in this.$route.query.formation.formateurs"
                                    :key="o">
                                    <v-icon left>
                                        fas fa-user
                                    </v-icon>
                                    {{obj.nom+" "+obj.prenom}}
                                </v-tab>
                                
                                <v-tab-item
                                    v-for="(obj,o) in this.$route.query.formation.formateurs"
                                    :key="o">
                                    <v-card  
                                        class="v-card-profile mt-16"
                                        color="grey lighten-2"
                                        elevation="24"
                                        outlined
                                        shaped
                                        >
                                            <!-- <v-avatar 
                                                style="position:relative;top: -50px"
                                                size="150">
                                                <img
                                                    src="../assets/profile.jpg"
                                                    alt="John"
                                                >
                                            </v-avatar> -->
                                        <v-card-text 
                                        :class="[$vuetify.breakpoint.smAndDown ? 'text-justify': 'headline ']"
                                        class="black--text">
                                            <h1>
                                                {{obj.nom+" "+obj.prenom+" "+obj.autre}}
                                            </h1>
                                            <v-row class="text-left black--text mt-10 mx-auto">
                                                <v-col 
                                                    cols="12"
                                                    md="6"
                                                    lg="6">
                                                    <div v-if="obj.etudes != null">
                                                        <v-row>
                                                            <v-icon class="mr-3 black--text" size="30" >
                                                                fas fa-graduation-cap
                                                            </v-icon>
                                                            <h2 class="mt-5">
                                                                Etudes
                                                            </h2>
                                                        </v-row>
                                                        <h3 
                                                            v-for="(etu,e) in obj.etudes"
                                                            :key="e"
                                                            class="mt-5">
                                                            <v-icon class="mr-1" size="15" >
                                                                fas fa-plus
                                                            </v-icon>
                                                            {{etu.e}}
                                                        </h3>
                                                    </div>
                                                </v-col>
                                                <v-col 
                                                    cols="12"
                                                    md="6"
                                                    lg="6">
                                                    <div  v-if="obj.experiences != null">
                                                        <v-row>
                                                            <v-icon class="mr-3 black--text" size="30">
                                                                fas fa-briefcase
                                                            </v-icon>

                                                            <h2 class="mt-5">
                                                                Experience Professionnelle
                                                            </h2>
                                                        </v-row>
                                                        <h3 
                                                            v-for="(exp,e) in obj.experiences"
                                                            :key="e"
                                                            class="mt-5">
                                                            <v-icon class="mr-1" size="15" >
                                                                fas fa-plus
                                                            </v-icon>
                                                            {{exp.e}}
                                                        </h3>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>
                        </v-tabs>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
  </v-card>
  <Footer id="contact"/>
    </v-row>
</template>

<script>
export default {
    name:'PageFormations',
    components: {
        Navbar: () => import('@/components/NavBar-formation'),
        Footer: () => import('@/components/Footer-formation'),
    },
    data() {
        return {
            currentItem: 'tab-Web',
            objectifs:this.$route.query.formation.objectifs,
            img:this.$route.query.formation.img,
            path:'../assets/images/',
            window: {
                width: 0,
                height: 0
            },
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        }
    }
}
</script>