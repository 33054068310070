<style>
    @import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

.myFont {
  font-family: 'Source Serif Pro', serif;
}

</style>
<template class="myFont">
  <v-app id="app" class="myFont">
    
    <v-main>
      <router-view></router-view>
      <go-top
      :size="50"
      bg-color="#212121"
      ></go-top>
    </v-main>
    
  </v-app>
</template>

<script>
// import HelloWorld from './views/HelloWorld';
import GoTop from '@inotom/vue-go-top';

  
export default {
  name: 'App',

  components: {
    // HelloWorld,
    GoTop
   
  },

  computed: {
    mini() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
};
</script>
